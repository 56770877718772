<template>
  <vx-card title="Detail Collection Plan">
    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-input class="w-full" type="text" v-model="territory" readonly />
      </div>
    </div>
    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Date</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-input class="w-full" type="text" v-model="date" readonly />
      </div>
    </div>
    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Sales</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-input class="w-full" type="text" v-model="sales" readonly />
      </div>
    </div>
    <div class="vx-row w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Total Value</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-input class="w-full" readonly v-model="total_value" />
      </div>
    </div>
    <div class="vx-row w-1/2 mt-2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Notes</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-textarea class="w-full" v-model="notes" readonly />
      </div>
    </div>

    <!-- table -->
    <div class="vx-row mb-2">
      <div class="core vx-col w-full mb-base">
        <div>
          <vs-table
            search
            stripe
            border
            description
            :sst="false"
            :data="table.data"
            :max-items="table.data.length"
          >
            <template slot="thead">
              <vs-th>Customer</vs-th>
              <vs-th>Ship To</vs-th>
              <vs-th>Invoice No</vs-th>
              <vs-th>Invoice Date</vs-th>
              <vs-th>Due Date</vs-th>
              <vs-th>Invoice Value</vs-th>
              <vs-th>Remaining Value</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>{{ tr.customer }}</vs-td>
                <vs-td>{{ tr.ship_to }}</vs-td>
                <vs-td>{{ tr.invoice_no }}</vs-td>
                <vs-td>{{ tr.invoice_date | formatDate }}</vs-td>
                <vs-td>{{ tr.due_date | formatDate }}</vs-td>
                <vs-td>{{ tr.invoice_value | formatNumber }}</vs-td>
                <vs-td>{{ tr.remaining_value | formatNumber }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>

        <div class="vs-row mt-4">
          <div class="vs-col">
            <vs-button
              @click="onClickBack"
              size="medium"
              color="danger"
              icon-pack="feather"
              icon="icon-arrow-left"
              >Back</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
import { formatNumber } from "../../../utils/common";

export default {
  data() {
    return {
      table: this.tableDefaultState(),
      total_value: 0,
      territory: null,
      sales: null,
      date: null,
      routes: [],
      notes: null,
    };
  },
  mounted() {
    this.getOneData();
  },
  methods: {
    async getOneData() {
      this.$vs.loading();
      const id = this.$route.params.id;
      let response = await this.$http.get(
        `/api/sfa/v1/collection-plan/${id}/detail`
      );

      let data = response.data;
      this.territory = data.territory;
      this.notes = data.notes;
      this.sales = data.sales;
      this.date = moment(data.date).format("DD-MM-YYYY");
      this.total_value = data.bill_total;
      this.table.data = data.detail_items;
      this.$vs.loading.close();
    },
    tableDefaultState() {
      return {
        data: [],
      };
    },
    onClickBack() {
      // back previous page
      this.$router.go(-1);
    },
  },
  filters: {
    formatNumber(value) {
      return formatNumber(value);
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
  },
};
</script>
